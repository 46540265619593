import React, { useCallback, useEffect, useState } from "react";
// import Link from "next/link";
import Image from "next/image";
import RegiSuccess from "components/shared/modal/regiSuccess";
//import LoginSuccess from "components/shared/modal/loginSuccess";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/authActions";
import OtpInput from "react-otp-input";
import styled from "styled-components";
import { errorHelper, GOOGLE_LOGIN, PHONE_LOGIN } from "constants/loginConstants";
import RatingModal from "components/shared/modal/ratingModal";
import { useRouter } from "next/router";
import RatingConfirmedModal from "components/shared/modal/ratingConfirmedModal";
import { signIn } from "next-auth/react";
// import * as Icon from 'react-bootstrap-icons';
import { useSession } from "next-auth/react";
import CountDown from "./couter";
import { FormattedMessage } from "react-intl";
import LoginSuccess from "components/shared/modal/loginSucces";
import { NextSeo } from "next-seo";
import { SimpleTimer } from "./simpleTimer";
import { useWindowSize } from "api/commonConstants";
import DOMPurify from "dompurify";
import { GenerateUniqId } from "utils/deviceIdGenerator";
// import ClevertapReact from "utils/clevertapHelper";

export const OTPinputBox = styled(OtpInput)`
  // width: 55px;
  // height: 55px;
  // padding: 5px;
`;
export const OTPcontainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  span.error {
    padding: 10px;
    display: inline-flex;
    justify-content: end;
    color: red;
  }
  > div {
    justify-content: flex-start;
  }
  input {
    padding: 5px;
    width: 100% !important;
    border-radius: 1rem;
    border: 2px solid #bdbdbd;
    background: transparent;
    height: 100%;
    font-size: 2rem;
    outline: none;
    caret-color: #d9368b;
  }
  button {
    width: 100%;
    margin-top: 40px;
  }
`;
const UserLogin = ({ providers }) => {
  var dateYear = new Date();
  dateYear = dateYear.getFullYear();
  //const [show1, setShow1] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const { data: session } = useSession();
  const size = useWindowSize();
  //const { userData } = useSelector(({ UserReducer }) => UserReducer);
  const handleClose = () => {
    setShow(false);
  };
  const handleonfirmShow = () => {
    setShow(false);
    setConfirmShow(true);
  };
  const handleConfirmClose = () => setConfirmShow(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [otpResend, setOtpResend] = useState(false);
  const [counterDone, setCounterDone] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState(true);
  const [showOTPBox, setShowOTPBox] = useState(false);
  const [isAllowed, setisAllowed] = useState(true);
  const [language, setLanguage] = useState({ selectedLang: 'hi' });

  const { encryptedToken, showSuccessModal, error, entity, otpClear, message } =
    useSelector(({ AuthReducer }) => AuthReducer);


  const responseGoogle = useCallback(() => {
    if(router.pathname.includes("/login")){
      GenerateUniqId().then((deviceId) => {
        // const cleverTapId = localStorage
        //   .getItem("WZRK_G")
        //   .toString()
        //   .replace("%22", "")
        //   .replace("%22", "");
  
        console.log("responseGoogle callback called");
  
        const deviceToken = localStorage.getItem("deviceToken");
  
        console.log("responseGoogle callback called device", deviceToken);
  
        let loginObj = {
          loginType: GOOGLE_LOGIN,
          deviceType: "web",
          deviceId,
          deviceToken,
          facebookId: process.env.NEXT_PUBLIC_FACEBOOK_ID,
          googleId: session?.user?.uniqueId,
          appleId: "",
          fullname: session?.user?.name,
          email: session?.user?.email,
          GoogleToken: session?.id_token,
          isMobile: size?.width > 600 ? false : true
        };
        console.log("responseGoogle callback called payload", loginObj);
        dispatch(actions.LoggedIn(loginObj));
      });
    }
  
  }, [
    dispatch,
    session?.user?.email,
    session?.user?.uniqueId,
    session?.user?.name,
  ]);

  const phoneChange = (e) => {
    const sanitizedValue = DOMPurify.sanitize(e.target.value)
    if (sanitizedValue.length < 11) {
      setPhoneNumber(sanitizedValue);
    }
    setCounterDone(true);
  };

  const handleClick = () => {
    if (encryptedToken) {
      setShowModal(false);
      // ClevertapReact.event("app login confirm");
      // ClevertapReact.event("app_login_successful");
      router.push(router.query?.returnUrl || router.query?.callbackUrl || "/");
    } else {
      let submitObj = {
        id: entity?._id,
        otp,
        isMobile: size?.width > 600 ? false : true
      };

      let loginObj = {
        phoneNumber: phoneNumber || entity?.phoneNumber,
        loginType: PHONE_LOGIN,
        deviceType: "web",
        deviceId: "",
        deviceToken: "",
        facebookId: "",
        googleId: "",
        appleId: "",
        fullname: "",
        isMobile: size?.width > 600 ? false : true
      };
      if ((entity?.phoneNumber || phoneNumber) && otp) {
        console.log("submit obj", loginObj)
        dispatch(actions.SubmitOTP(submitObj));
        //router.push(router.query?.returnUrl || router.query?.callbackUrl);
      } else {
        dispatch(actions.OtpGenerate(loginObj));
      }
      setTimeout(() => setShowOTPBox(true), 1000)
    }
  };

  useEffect(() => {
    setShowModal(showSuccessModal);
  }, [showSuccessModal]);

  let onTimesup = () => {
    // alert(`Time's up!`);
    setOtpResend(true);
    setCounterDone(false);
  };

  const handleResendOTP = (_id) => {
    let count = localStorage.getItem("otpCount")
      ? Number(localStorage.getItem("otpCount"))
      : 0;
    localStorage.setItem("otpCount", count + 1);
    dispatch(actions.ResendOtpGenerate({ id: _id, isMobile: size?.width > 600 ? false : true }));
    setOtpResend(false);
    setCounterDone(true);
  };

  useEffect(() => {
    setOTP("");
  }, [otpClear]);

  useEffect(() => {
    const localLanguage = JSON.parse(localStorage.getItem("i18nConfig"))
    if (localLanguage) {
      setLanguage(localLanguage)
    }
  }, [])


  useEffect(() => {
    let counter = Number(localStorage.getItem("loginCount"));
    let counter1 = Number(localStorage.getItem("otpCount"));
    if (counter >= 3 || counter1 >= 3) {
      localStorage.removeItem("loginCount");
      localStorage.removeItem("otpCount");
      setisAllowed(false);
      const expirationMs = 60 * 60 * 1000; // 1 hour in milliseconds
      const expirationTime = new Date().getTime() + expirationMs;
      const item = {
        value: false,
        expirationTime: expirationTime,
      };
      // Convert the object to a JSON string and store it in localStorage
      localStorage.setItem("loginFreeze", JSON.stringify(item));
    }
    let data = JSON.parse(localStorage.getItem("loginFreeze"));
    console.log(data);
    if (data) {
      if (data.value === false) {
        setisAllowed(false);
      } else {
        setisAllowed(true);
      }
    }
    if (data && new Date().getTime() > Number(data?.expirationTime)) {
      console.log("start3");
      localStorage.removeItem("loginFreeze");
      setisAllowed(true);
    }
  });
  const toShowOtpContainer = entity?._id && showOTPBox;

  return (
    <>
      <NextSeo
        title="Login/Signup | TheLallantop"
        description="Login/Signup | TheLallantop"
      />

      {/* Activate for old UI */}
      {/* <div className="login-head-m">
        <div className="cat-logo">
          <a href="/" passHref>
            <a>
              <img
                src="/assets/images/m-logo.svg"
                loading="lazy"
                width="40"
                height="40"
                alt="Lallantop"
              />
            </a>
          </a>
        </div>
      </div> */}
      {size.width && size?.width > 600 ? 
      <div className="login-container">
        <div className="log_lhs">
          <a href="/" passHref>
            <a
              className="page_back"
              onClick={() => {
                // ClevertapReact.event("header_back_click");
                // if (phoneNumber) {
                //   ClevertapReact.event("app_login_otp_back");
                // }
                router.back();
              }}
            >
              <span className="icon-back-arrow"></span>
            </a>
          </a>
          <div className="welcontent">
            <Image
              src="/assets/images/lallatop-logo.svg"
              width="200"
              height="169"
              alt="Lallantop"
            />
            {/* <h2 className="brandtitle">मम्मी कसम मिलेगा मारक मज़ा</h2> */}
          </div>
          <p className="copylallan">
            © {dateYear} LALLANTOP. All rights reserved.
          </p>
        </div>

        <div className="log_rhs">
          <div className="welcome_h1">
            <h1>
              <FormattedMessage id="welcome" defaultMessage="वेलकम!" />
            </h1>
            <p className="welcome_st">
              <FormattedMessage
                id="login_ahead"
                defaultMessage="आगे जाने के लिए लॉगिन करें"
              />
            </p>
          </div>
          <div className="social-login-box">
            {providers &&
              Object.values(providers).map((provider) => {
                if (provider.id === "facebook") {
                  return (
                    <div
                      style={{ border: "1px solid #1877F2 !important" }}
                      className="socialcomm"
                      key={provider.name}
                      disabled
                    >
                      <Image
                        src={`/assets/images/${provider.name.toLowerCase()}-login.png`}
                        width="38"
                        height="38"
                        alt="Lallantop"
                      />
                      <span
                        style={{ color: "#1877F2" }}
                        onClick={() => {
                          if (termsCheckbox) {
                            let payload = {
                              Facebook: false,
                              Google: false,
                              Email: false,
                              Phone: false,
                            };
                            payload[provider.name] = true;
                            signIn(provider.id);
                          }
                        }}
                      >
                        Login with {provider.name}
                      </span>
                    </div>
                  );
                } else if (provider.id !== "googleonetap") {
                  return (
                    <div
                      onClick={() => {
                        if (termsCheckbox) {
                          let payload = {
                            Facebook: false,
                            Google: false,
                            Email: false,
                            Phone: false,
                          };
                          payload[provider.name] = true;
                          // ClevertapReact.event("app_login_method", payload);
                          signIn(provider.id);
                        }
                      }}
                      className="socialcomm"
                      key={provider.name}
                      disabled
                    >
                      <Image
                        src={`/assets/images/${provider.name.toLowerCase()}-login.png`}
                        width="38"
                        height="38"
                        alt="Lallantop"
                      />
                      <span>Sign in with {provider.name}</span>
                    </div>
                  );
                }
              })}
          </div>
          {/* <div className="social-login-box">
            <div className="socialcomm">
                <Image
                  src="/assets/images/facebook-login.png"
                  width="38"
                  height="38"
                  alt="Lallantop"
                />
                <span>Sign in with Facebook</span>
              </div>

            <FacebookLogin
              appId="1088597931155576"
              autoLoad={true}
              fields="name,email,picture"
              onClick={componentClicked}
              callback={responseFacebook}
              cssclassName="socialcomm"
              textButton="Sign in with Facebook"
              icon={
                <Image
                  src="/assets/images/facebook-login.png"
                  width="38"
                  height="38"
                  alt="Lallantop"
                />
                className="socialcomm"
              }
            />
          </div> */}

          <div className="divider">
            <p className="divi_text">
              <FormattedMessage
                id="or_sign_in_with_your_phone_number"
                defaultMessage="या अपने फ़ोन नंबर से साइन इन करें"
              />
            </p>
          </div>

          <form>
            <div className="formcommon">
              <h3 className="formhead">
                <FormattedMessage
                  id="mobile_number"
                  defaultMessage="मोबाइल नंबर"
                />
              </h3>
              <div className="formbox">
                <input
                  type="Number"
                  name=""
                  placeholder="+91"
                  className="form-control concode"
                  disabled="disabled"
                />
                <div className="number-input">
                  <input
                      type="number"
                    name="phoneNumber"
                    className="form-control phnnum"
                    maxLength={10}
                      value={phoneNumber || entity?.phoneNumber}
                    onChange={(e) => {
                      if (e.target.value.length === 11) return false;
                      phoneChange(e);
                    }}
                    // disabled={entity?._id}
                  />
                </div>
              </div>
            </div>

            {entity?._id && !error && showOTPBox && (
              <>
                <div className="formcommon">
                  <div className="otp-warp-head">
                    <h3 className="formhead otphead">OTP</h3>
                    {counterDone && (
                      <div className="otp-info-text">
                        <FormattedMessage
                          id="otp_sending"
                          defaultMessage="OTP भेजा जा रहा है"
                        />
                      </div>
                    )}
                    {otpResend && (
                      <div className="otp-info-text">
                        <FormattedMessage
                          id="resend_otp"
                          defaultMessage="OTP नहीं मिला ?"
                        />
                      </div>
                    )}
                  </div>
                  {/* {counterDone && ( */}
                  <div className="otpcont">
                    <OTPcontainer>
                      <OTPinputBox
                        value={otp}
                        onChange={setOTP}
                        numInputs={5}
                        separator={null}
                        className="otp-input-box"
                        isInputNum={true}
                        type="number"
                      />
                      {/* {error && (
                        <div>
                          <span className="error">{message}</span>
                        </div>
                      )} */}
                    </OTPcontainer>
                    <div className="otpbox">
                      {counterDone && (
                        <>
                          <p className="otpmsg">
                            <FormattedMessage
                              id="otp_sending"
                              defaultMessage="OTP भेजा जा रहा है"
                            />
                          </p>
                          <CountDown onTimesup={onTimesup} duration={120} />
                        </>
                      )}
                      {otpResend && (
                        <>
                          <p className="otpmsg">
                            <FormattedMessage
                              id="resend_otp"
                              defaultMessage="OTP नहीं मिला ?"
                            />
                          </p>
                          <button
                            type="button"
                            disabled={isAllowed ? false : true}
                            className="resendotp btn"
                            onClick={() => {
                              // ClevertapReact.event("app_login_otp_resend");
                              handleResendOTP(entity._id);
                            }}
                          >
                            <FormattedMessage
                              id="resend"
                              defaultMessage="फिर से भेजें"
                            />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                </div>
                {/* <div className="actionbtn">
                <button type="button" className="confirmbtn btn">
                  कन्फ़ॉर्म
                </button>
              </div> */}
              </>
            )}

            <div className="formcommon">
              <div className="termsCheckbox">
                <input
                  type="checkbox"
                  onClick={(e) => {
                    setTermsCheckbox(e.target.checked);
                  }}
                  checked={termsCheckbox}
                  />&nbsp;
                <p>
                    I have read and accepted the&nbsp;
                  <a href="/terms-of-use" passHref>
                    <a>T&C</a>
                    </a>&nbsp;
                    and the&nbsp;
                  <a href="/privacy-policy" passHref>
                    <a>Privacy Policy</a>
                    </a>&nbsp;
                  for use of the Lallantop Website.
                </p>
              </div>
              {!termsCheckbox && (
                <div>
                  <span className="error-tc">
                    * Please check the T&C checkbox to login
                  </span>
                </div>
              )}
            </div>

            <div className="actionbtn">
              <button
                disabled={isAllowed ? false : true}
                type="button"
                onClick={() => {
                  if (
                    phoneNumber.match(/^[0-9]+$/) &&
                    phoneNumber.length === 10
                  ) {
                    // ClevertapReact.event("app_login_phone_confirm");

                    if (termsCheckbox) handleClick();
                  } else {
                    // ClevertapReact.event("app_login_phone_confirm");
                  }
                }}
                className={`confirmbtn btn${phoneNumber.length === 10 && phoneNumber.match(/^[0-9]+$/)
                  ? " active"
                  : ""
                  }`}
              >
                <FormattedMessage id="confirm" defaultMessage="पुष्टि करें" />
              </button>
                {!isAllowed && (
                  <p style={{ color: "red", marginTop: "2rem" }}>
                    You have tried multiple times. Please try after 1 Hour.
                  </p>
                )}
            </div>
          </form>
          {/* <Button variant="primary" onClick={handleShow}>
            Rating
          </Button> */}
          <RatingModal
            show={show}
            handleClose={handleClose}
            handleonfirmShow={handleonfirmShow}
          />
          <RatingConfirmedModal
            show={confirmShow}
            handleClose={handleConfirmClose}
          />
        </div>
      </div>
        :
      <div className="new-login-container">
        <div className="log_rhs">
          <a href="/" passHref>
            <a
              className="page_back"
              onClick={() => {
                if (showOTPBox) {
                  setShowOTPBox(false)
                } else {
                  router.push(router?.query?.callbackUrl || "/")
                }
              }}
            >
              <span className="icon-back-arrow"></span>
            </a>
          </a>
          <div className="welcontent">
            <Image
              src="/assets/images/lallantop-logo.png"
              width="80"
              height="80"
              alt="Lallantop"
              loading="lazy"
            />
            {/* <h2 className="brandtitle">मम्मी कसम मिलेगा मारक मज़ा</h2> */}
          </div>
          <div className="welcome_h1">
            <h1 style={{ color: "white!important" }}>
              <FormattedMessage id="welcome" defaultMessage="वेलकम!" />
            </h1>
            <p className="welcome_st" style={{ color: "white!important" }}>
              <FormattedMessage
                id="login_ahead"
                defaultMessage="आगे जाने के लिए लॉगिन करें"
              />
            </p>
          </div>

          <div className="new-login-container-lower">
              {showSuccessModal ? <>
                <div className="mobile-success-page">
                  <img src="/assets/images/check-icon.png" height={50} width={50} />
                  <h1>{entity?.isNew ?
                    <FormattedMessage
                      id="registration_successful"
                      defaultMessage="रजिस्ट्रेशन सफल रहा"
                    /> :
                    <FormattedMessage
                      id="successfully_logged_in"
                      defaultMessage="सफलतापूर्वक लॉगिन किया गया"
                    />}
                  </h1>
                  <p>{entity?.isNew &&
                    <FormattedMessage
                      id="account_created_successfully"
                      defaultMessage="बधाई!आपका अकाउंट बन गया है"
                    />}</p>
                </div>
                <div className="confirmactionbtn">
                  <button
                    type="button"
                    className="confirmbtn btn btn-pink"
                    onClick={() => router.push(router.query?.returnUrl || router.query?.callbackUrl || "/")}
                  >
                    <FormattedMessage
                      id="continue_text"
                      defaultMessage="जारी रखें"
                    />
                    <span className="icon-arrow-right"></span>
                  </button>
                </div>
              </>
                : toShowOtpContainer ? (<>
                  <div>
                    <form>
                      <div className="formcommon">
                        <h3 className="formhead">
                          <FormattedMessage
                            id="enter_valid_otp"
                            defaultMessage="सही OTP बताएं"
                          />
                        </h3>
                        <>
                          <div className="edit-info">
                            <FormattedMessage
                              id="your_mobile_number"
                              defaultMessage="आपके मोबाइल नंबर"
                            />&nbsp;
                            <a onClick={() => {

                              setShowOTPBox(false)
                            }}>
                              {phoneNumber}
                              <img
                                src="/assets/images/edit.png"
                                width={15}
                                height={15}
                              />
                            </a>
                            <br />
                            <FormattedMessage
                              id="otp_sended"
                              defaultMessage=" "
                            />
                      </div>
                          <div className="formcommon-otp">
                            <div className="otpcont">
                              <OTPcontainer>
                                <OTPinputBox
                                  value={otp}
                                  onChange={setOTP}
                                  numInputs={5}
                                  separator={null}
                                  className="otp-input-box"
                                  isInputNum={true}
                                  type="number"
                                />
                                {/* {error && (
                        <div>
                          <span className="error">{message}</span>
                        </div>
                      )} */}
                              </OTPcontainer>
                              <div className="otpbox">
                                {otpResend && isAllowed ? <a className="otpmsg-link" onClick={() => {
                                  handleResendOTP(entity._id);
                                }}>
                                  <FormattedMessage
                                    id="resend_new_otp"
                                    defaultMessage="OTP फिरसे भेजें"
                                  />
                                </a> :
                                  <p className="disabled-text"><FormattedMessage
                                    id="resend_new_otp"
                                    defaultMessage="OTP फिरसे भेजें"
                                  /></p>
                                }
                                {counterDone &&
                                  <SimpleTimer durationInSeconds={120} onComplete={onTimesup} />
                                }
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                      {error && <p style={{ color: "red", marginTop: "2rem", textAlign: 'center' }}>
                        {language?.selectedLang === "hi" ? (message in errorHelper ? errorHelper[message] : "कुछ गलत हुआ") : message}</p>}
                    </form>
                  </div>
                  <div className="confirmactionbtn">
                    <button
                      type="button"
                      className={`confirmbtn btn ${otp ? "btn-pink" : "btn-grey"}`}
                      disabled={otp ? false : true}
                      onClick={() => handleClick()}
                    >
                      <FormattedMessage
                        id="verify"
                        defaultMessage="वेरिफाई"
                      />
                      <span className="icon-arrow-right"></span>
                    </button>
                  </div>
                </>) :
                  <>
                    <div>
                      <form>
                        <div className="formcommon">
                          <h3 className="formhead">
                            <FormattedMessage
                              id="write_mobile_number"
                              defaultMessage="मोबाइल नंबर"
                            />

                          </h3>
                          <div className="formbox">
                            <input
                              type="Number"
                              name=""
                              placeholder="+91"
                              className="form-control concode"
                              disabled="disabled"
                            />
                            <div className="number-input">
                              <input
                                type="number"
                                name="phoneNumber"
                                className="form-control phnnum"
                                maxLength={10}
                                value={phoneNumber}
                                onChange={(e) => {
                                  if (e.target.value.length === 11) return false;
                                  phoneChange(e);
                            }}
                            // disabled={entity?._id}
                              />
                            </div>
                            <div className="actionbtn">
                              <button
                                disabled={isAllowed ? false : true}
                                type="button"
                                onClick={() => {
                                  if (
                                    phoneNumber.match(/^[0-9]+$/) &&
                                    phoneNumber.length === 10
                                  ) {
                            // ClevertapReact.event("app_login_phone_confirm");

                                  if (termsCheckbox) handleClick();
                                } else {
                                  // ClevertapReact.event("app_login_phone_confirm");
                                }
                              }}
                                className={`confirmbtn btn${phoneNumber.length === 10 &&
                                  phoneNumber.match(/^[0-9]+$/)
                                  ? " active"
                                  : ""
                                  }`}
                              >
                                <FormattedMessage
                                  id="confirm"
                                  defaultMessage="पुष्टि करें"
                                />
                              </button>
                            </div>

                          </div>
                          {!isAllowed && (
                            <p style={{ color: "red", marginTop: "2rem", textAlign: 'center' }}>
                              You have tried multiple times. Please try after 1
                              Hour.
                            </p>
                          )}
                          {error && <p style={{ color: "red", marginTop: "2rem", textAlign: 'center' }}>
                            {language?.selectedLang === "hi" ? (message in errorHelper ? errorHelper[message] : "कुछ गलत हुआ") : message}</p>}
                        </div>
                      </form>


            <div className="divider">
              <p className="divi_text">
                          <FormattedMessage id="or_sign_in" defaultMessage="या" />
              </p>
            </div>
            <div className="social-login-box">
              {providers &&
                Object.values(providers).map((provider) => {
                  if (provider.id === "facebook") {
                    return (
                      <div
                        style={{ border: "1px solid #1877F2 !important" }}
                        className="socialcomm"
                        key={provider.name}
                        disabled
                      >
                        <Image
                          src={`/assets/images/${provider.name.toLowerCase()}-login.png`}
                          width="38"
                          height="38"
                          alt="Lallantop"
                        />
                        <span
                          style={{ color: "#1877F2" }}
                          onClick={() => {
                            if (termsCheckbox) {
                              let payload = {
                                Facebook: false,
                                Google: false,
                                Email: false,
                                Phone: false,
                              };
                              payload[provider.name] = true;
                              signIn(provider.id);
                            }
                          }}
                        >
                          Login with {provider.name}
                        </span>
                      </div>
                    );
                  } else if (provider.id !== "googleonetap") {
                    return (
                      <div
                        onClick={() => {
                          if (termsCheckbox) {
                            let payload = {
                              Facebook: false,
                              Google: false,
                              Email: false,
                              Phone: false,
                            };
                            payload[provider.name] = true;
                            // ClevertapReact.event("app_login_method", payload);
                            signIn(provider.id);
                          }
                        }}
                        className="socialcomm"
                        key={provider.name}
                        disabled
                      >
                        <Image
                          src={`/assets/images/${provider.name.toLowerCase()}-login.png`}
                          width="38"
                          height="38"
                          alt="Lallantop"
                        />
                        <span>Sign in with {provider.name}</span>
                      </div>
                    );
                  }
                })}
            </div>
                    </div>
            <div className="formcommon">
                      <div className="terms-text">
                        By signing in, you agree to our
                      </div>
                      <p className="footer-section">
                        <a href="/terms-of-use">Terms & conditions</a> |&nbsp;
                        <a href="/privacy-policy">Privacy Policy</a> |&nbsp;
                        <a href="/quiz/terms-and-conditions?showBtn=true">Quiz T&C</a>
                      </p>
                    </div>
                  </>
              }
            </div>
          <RatingModal
            show={show}
            handleClose={handleClose}
            handleonfirmShow={handleonfirmShow}
          />
          <RatingConfirmedModal
            show={confirmShow}
            handleClose={handleConfirmClose}
          />
        </div>
      </div>
      }
      {size?.width && size?.width > 600 && <>
      {encryptedToken && showSuccessModal && entity?.isNew === false ? (
        <LoginSuccess onClick={handleClick} show={showModal} />
      ) : (
        <RegiSuccess onClick={handleClick} show={showModal} />
      )}
      </>}
    </>
  );
};

export default UserLogin;
